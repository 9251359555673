import axios from 'axios';
import {LOGIN_USER} from './actionTypes'

var CryptoJS = require("crypto-js"); 

export const loginUser = () =>  {
    const dataToSubmit = { title: "Vue POST Request Example" };
    //let res = false;
     axios.post('/api/users/login',dataToSubmit)
            .then(response => response.data
                // if(response.data.loginsucces)
                // {
                //     res = true;
                //     //dispatch(loginSuccessAction(res));
                //     return {
                //         type: LOGIN_USER,
                //         payload : res
                //     }   
                // }
            );
    console.log("api called with response !!!");    
    //console.log(request);
}

export const generateData = (formdata, formName) =>{
    let dataToSubmit = {};

    for(let key in formdata){
        dataToSubmit[key] = formdata[key].value;
        if(key === 'password') {

            var ciphertext = CryptoJS.AES.encrypt(JSON.stringify(formdata[key].value), 'my-secret-key@123').toString();
            console.log('Encrypt Data -')
            console.log(ciphertext);
            //dataToSubmit[key] = ciphertext;
        }
    }

    return dataToSubmit;
}

export async function loginUser1(data) {
    const response = await axios.post('/api/users/login',data)
    let res = false;
    console.log("response");
    console.log(response.data);
    if(response.data.loginsucces) {
        res = true;
      
    }
    else {

    }
    return {
        type: LOGIN_USER,
        payload : res
    }   
}


// export function loginUser(data) 
// {
//     const dataToSubmit = { title: "Vue POST Request Example" };
//     let res = false;
//     const request = axios.post('/api/users/login',dataToSubmit)
//             .then(response => {
//                 if(response.data.loginsucces)
//                 {
//                     res = true;
//                 }
//             });
//     console.log("api called with response !!!");
//     return {
//         type: LOGIN_USER,
//         payload : res
//     }
// }

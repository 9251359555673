import {LOGIN_USER} from '../actions/actionTypes'
import initialState from "./initialState";

export default function(state=initialState, action) {
    switch(action.type){
        case LOGIN_USER:
            {
                console.log("abc");
                console.log(state);
                console.log(action.payload);
                if(!action.payload)
                {
                    state = { client : "Ashish1"}
                }
                console.log("state show");
                console.log(state);
                return state;
            }
        default:
            return state;
    }
}
import React, { Component } from 'react';

import '../../node_modules/font-awesome/scss/font-awesome.scss';


import { BrowserRouter } from 'react-router-dom';
import Routes from './routes';

class App extends Component {
    render() {
        return (

            <BrowserRouter>
                <Routes />
            </BrowserRouter>
            // <Dashboard/>
            // <SignIn/>
        );
    }
}

export default App;

import React from 'react';
import { NavLink} from "react-router-dom";
import { connect } from 'react-redux';


import '../../assets/scss/style.scss';
import {loginUser, loginUser1, generateData} from '../actions/actions';

class SignUp1 extends React.Component {

    state = {
        formdata:{
            username: {
                value: '',
                validation:{
                    required: true,
                    email: true
                },
                valid: true,
                touched: false,
                validationMessage:''
            },
            password: {
                value: '',
                validation:{
                    required: true,
                    email: true
                },
                valid: false,
                touched: false,
                validationMessage:''
            },
            client: {
                value: '',
                validation:{
                    required: true,
                    email: true
                },
                valid: false,
                touched: false,
                validationMessage:'error'
            },
        }
    }

    updateForm = (event) => {
        console.log(); 
        this.state.formdata[event.target.id].value = event.target.value;
        const newFormdata = {
            ...this.state.formdata
        }
        newFormdata[event.target.id].value = event.target.value;
        this.setState(newFormdata);     
    }

    submitForm = (event) =>{
        event.preventDefault();
        console.log("Login Button clicked !!!");
        let dataToSubmit = generateData(this.state.formdata,'login');
        console.log(dataToSubmit)
        loginUser1(dataToSubmit).then(data => {
            if(data.payload === true) {
                this.props.history.push("/dashboard");
            } else{
                console.log(false)
            }
        });
    }

    render () {
        // console.log("props start");
        // console.log(this.props);
        // console.log("props end");
        //this.setState()
        return(
           
                <div className="auth-wrapper">
                    <div className="auth-content">
                        <div className="auth-bg">
                            <span className="r"/>
                            <span className="r s"/>
                            <span className="r s"/>
                            <span className="r"/>
                        </div>
                        <div className="card">
                            <div className="card-body text-center">
                                <div className="mb-4">
                                    <i className="feather icon-unlock auth-icon"/>
                                </div>
                                <h3 className="mb-4">Login</h3>
                                <div className="input-group mb-3">
                                    <input type="clientID" className="form-control" placeholder="Client ID"  id={'client'} onChange={(event)=> this.updateForm(event) } /> 
                                </div>
                                {this.state.formdata.client.valid ? '' : 
                                    <span style={{color: "red"}}>{this.state.formdata.client.validationMessage}</span>
                                }
                                <div className="input-group mb-3">
                                    <input type="username" className="form-control" placeholder="User Name"  id={'username'} onChange={(event)=> this.updateForm(event) }/>
                                </div>
                                {this.state.formdata.username.valid ? '' : 
                                    <span style={{color: "red"}}>{this.state.formdata.username.validationMessage}</span>
                                }
                                <div className="input-group mb-4">
                                    <input type="password" className="form-control" placeholder="password"  id={'password'} onChange={(event)=> this.updateForm(event) }/>
                                </div>
                                {this.state.formdata.password.valid ? '' : 
                                    <span style={{color: "red"}}>{this.state.formdata.password.validationMessage}</span>
                                }
                                <div className="form-group text-left">
                                    <div className="checkbox checkbox-fill d-inline">
                                        <input type="checkbox" name="checkbox-fill-1" id="checkbox-fill-a1" />
                                            <label htmlFor="checkbox-fill-a1" className="cr"> Save credentials</label>
                                    </div>
                                </div>
                                <button className="btn btn-primary shadow-2 mb-4" onClick={(event)=> this.submitForm(event)}>Login</button>
                                <p className="mb-2 text-muted">Forgot password? <NavLink to="/auth/reset-password-1">Reset</NavLink></p>
                                <p className="mb-0 text-muted">Don’t have an account? <NavLink to="/auth/signup-1">Signup</NavLink></p>
                            </div>
                        </div>
                    </div>
                </div>
           
        );
    }
}


const mapStateToProps = state => {
    return {
        client: state.user.client
    }
    
  }

  const mapDispatchToProps =   {
    loginUser
   }

export default connect(mapStateToProps, mapDispatchToProps)(SignUp1);
import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Home from '../components/SignIn1';
import Dashboard from '../components/dashboard';
const Routes = () => {
    return( 
        <Switch>
          <Route path="/dashboard" exact component={Dashboard}/>
          <Route path="/" exact component={Home}/>
        </Switch>
    )
  }
  
  
  export default Routes;
  